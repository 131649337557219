body {
}

/* Quill editor min height https://github.com/zenoamaro/react-quill/issues/426 */
.ql-editor {
  min-height: 8rem;
}

@font-face {
  font-family: 'ReithSans';
  src: url('./theme/fonts/BBCReithSans_W_Rg.eot');
  src: url('./theme/fonts/BBCReithSans_W_Rg.woff') format('woff'),
    url('./theme/fonts/BBCReithSans_W_Rg.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FontAwesome';
  src: url('./theme/fonts/fontawesome-webfont.eot');
  src: url('./theme/fonts/fontawesome-webfont.woff') format('woff'),
    url('./theme/fonts/fontawesome-webfont.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'ReithSans';
  src: url('./theme/fonts/BBCReithSans_W_Bd.eot');
  src: url('./theme/fonts/BBCReithSans_W_Bd.woff') format('woff'),
    url('./theme/fonts/BBCReithSans_W_Bd.woff2') format('woff2');
  font-weight: 600;
  font-style: 600;
}
@font-face {
  font-family: 'ReithSans';
  src: url('./theme/fonts/BBCReithSans_W_Lt.eot');
  src: url('./theme/fonts/BBCReithSans_W_Lt.woff') format('woff');
  font-weight: 300;
  font-style: 300;
}
